import React from 'react';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import DashboardIcon from '@material-ui/icons/Dashboard';
import BarChartIcon from '@material-ui/icons/BarChart';
import List from '@material-ui/core/List';
import paths from '../paths';

const NavBar = (props) => {
  const { section, onSectionChanged } = props;

  const handleListItemClick = (event, section) => {
    onSectionChanged(section);
  }

  return (
    <List>
      <ListItem button selected={section === paths.MONITORINGS}  onClick={event => handleListItemClick(event, paths.MONITORINGS)}>
        <ListItemIcon>
          <DashboardIcon />
        </ListItemIcon>
        <ListItemText primary="Monitorings" />
      </ListItem>
      <ListItem button  selected={section === paths.REPORTS} onClick={event => handleListItemClick(event, paths.REPORTS)}>
        <ListItemIcon>
          <BarChartIcon />
        </ListItemIcon>
        <ListItemText primary="Reports" />
      </ListItem>
    </List>
  );
}

export default NavBar;