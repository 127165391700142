import React, { useState, useEffect } from 'react';
import { useSnackbar } from 'notistack';
import Layout from '../../App/containers/Layout';
import ReportsList from './List';
import paths from '../../App/paths';
import AuthService from '../../Auth/services/AuthService';
import ReportsRepository from '../infrastructure/ReportsRepository';

export default function Reports() {
  const { enqueueSnackbar } = useSnackbar();
  const [reports, setReports] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const token = await AuthService.token();
        const reports = await ReportsRepository.retrieve(token);
        setReports(reports);  
        console.log('reports', reports)
        setReports(reports);
      } catch (error) {
        enqueueSnackbar(`Error retrieving your reports, ensure your account is properly configured and that you are connected to Internet.`, {
          variant: 'error'
        });
        setReports([]);
      }
    };
    fetchData();
  }, [enqueueSnackbar]);

  return (
    <Layout section={paths.REPORTS}>
      <ReportsList reports={reports} />
    </Layout>
  );
}