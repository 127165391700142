import axios, { AxiosResponse } from 'axios';
import Message from './Message';

const API_PATH = process.env.REACT_APP_API_PATH;

class Dispatcher {
  async dispatchCommand(command: Message): Promise<AxiosResponse> {
    return await axios({
      method: 'POST',
      url: `${API_PATH}${command.name}`,
      headers: {
        Authorization: `Bearer ${command.token}`
      },
      data: command.data
    });
  }

  async dispatchQuery(query: Message): Promise<AxiosResponse> {
    return await axios({
      method: 'GET',
      url: `${API_PATH}${query.name}`,
      headers: {
        Authorization: `Bearer ${query.token}`
      },
      params: { ...(query.data as object) }
    });
  }
}

export default new Dispatcher();
