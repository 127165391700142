import React from 'react';
import { Link } from 'react-router-dom';
import paths from '../paths';

const NotFound = () => (
  <div>
    <div>
      Not found
      Go to <Link to={paths.HOME}> Home</Link>
    </div>
  </div>
);

export default NotFound;