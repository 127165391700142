import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import CircularProgress from '@material-ui/core/CircularProgress';
import { blue } from '@material-ui/core/colors';

const useStyles = makeStyles(theme => ({
  wrapper: {
    margin: theme.spacing(1),
    position: 'relative'
  },
  buttonProgress: {
    color: blue[500],
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12
  }
}));

export default function AnalyzeMonitoring(props) {
  const classes = useStyles();
  const [open, setOpen] = useState(props.open);
  const [title, setTitle] = useState(props.title);
  const [start, setStart] = useState(props.start);
  const [end, setEnd] = useState(props.title);
  const [sending, setSending] = useState(false);
  const { handleClose, handleAnalyze } = props;

  useEffect(() => {
    setOpen(props.open);
    setTitle(props.title);
    setStart(props.start);
    setEnd(props.end);
    setSending(props.sending);
  }, [props.open, props.title, props.start, props.end, props.sending]);

  const handleAnalyzeClick = async () => {
    setSending(true);
    const handled = await handleAnalyze(title, start, end);
    if (!handled) {
      setSending(false);
    }
  };

  const handleEmailChanged = event => {
    setTitle(event.target.value);
  };

  const handleStartChanged = event => {
    setStart(event.target.value);
  };

  const handleEndChanged = event => {
    setEnd(event.target.value);
  };

  return (
    <Dialog open={open} onClose={handleClose} aria-labelledby="form-dialog-title">
      <DialogTitle id="form-dialog-title">Analyze Monitoring Audience</DialogTitle>
      <DialogContent>
        <DialogContentText>Create an Audiense report based on the monitoring's authors.</DialogContentText>
        <TextField
          autoFocus
          margin="dense"
          id="title"
          label="Report Title"
          type="text"
          value={title}
          fullWidth
          onChange={handleEmailChanged}
        />
        <TextField
          margin="dense"
          id="start"
          label="Start Date"
          InputLabelProps={{
            shrink: true
          }}
          type="date"
          value={start}
          fullWidth
          onChange={handleStartChanged}
        />
        <TextField
          margin="dense"
          id="end"
          label="End Date"
          InputLabelProps={{
            shrink: true
          }}
          type="date"
          value={end}
          fullWidth
          onChange={handleEndChanged}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="primary">
          Cancel
        </Button>
        <div className={classes.wrapper}>
          <Button onClick={handleAnalyzeClick} color="primary" variant="contained" disabled={sending}>
            Analyze
          </Button>
          {sending && <CircularProgress size={24} className={classes.buttonProgress} />}
        </div>
      </DialogActions>
    </Dialog>
  );
}
