import React, { useState, useEffect } from 'react';
import { useSnackbar } from 'notistack';
import { format } from 'date-fns';
import Layout from '../../App/containers/Layout';
import MonitoringsList from './List';
import AnalyzeMonitoring from './AnalyzeMonitoring';
import paths from '../../App/paths';
import AuthService from '../../Auth/services/AuthService';
import MonitoringsRepository from '..//infrastructure/MonitoringsRepository';
import ReportsRepository from '../../Reports/infrastructure/ReportsRepository';

export default function Monitorings() {
  const { enqueueSnackbar } = useSnackbar();
  const [monitorings, setMonitorings] = useState(null);
  const [analyzeDialogOpened, setAnalyzeDialogOpened] = useState(false);
  const [defaultTitle, setDefaultTitle] = useState('');
  const [groupId, setGroupId] = useState('');
  const [defaultStart, setDefaultStart] = useState('');
  const [defaultEnd, setDefaultEnd] = useState('');
  const [monitoringId, setMonitoringId] = useState('');

  const handleOpenAnalyze = (id, name, groupId, start, end) => {
    setMonitoringId(id);
    setGroupId(groupId);
    setAnalyzeDialogOpened(true);
    setDefaultTitle(`${name} - ${new Date().toLocaleString()}`);
    setDefaultStart(start);
    setDefaultEnd(end);
  };

  const handleCloseAnalyzeDialog = () => {
    setAnalyzeDialogOpened(false);
  };

  const handleAnalyze = async (title, start, end) => {
    const formatedStartDate = format(start, 'MM/DD/YYYY');
    const formatedEndDate = format(end, 'MM/DD/YYYY');

    try {
      const token = await AuthService.token();
      await ReportsRepository.create(token, monitoringId, groupId, title, formatedStartDate, formatedEndDate);
      enqueueSnackbar(`Successully requested audience analysis for monitoring ${title}`, {
        variant: 'success'
      });
      setAnalyzeDialogOpened(false);
      return true;
    } catch (error) {
      console.error('Error analyzing', error);
      enqueueSnackbar(`Error requesting audience analysis for monitoring ${title}`, {
        variant: 'error'
      });
      return false;
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const token = await AuthService.token();
        const monitorings = await MonitoringsRepository.retrieve(token);
        setMonitorings(monitorings);
      } catch (error) {
        enqueueSnackbar(
          `Error retrieving your monitorings, ensure your account is properly configured and that you are connected to Internet.`,
          {
            variant: 'error'
          }
        );
        setMonitorings([]);
      }
    };
    fetchData();
  }, [enqueueSnackbar]);

  return (
    <Layout section={paths.MONITORINGS}>
      <MonitoringsList monitorings={monitorings} handleOpenAnalyze={handleOpenAnalyze} />
      <AnalyzeMonitoring
        open={analyzeDialogOpened}
        handleClose={handleCloseAnalyzeDialog}
        handleAnalyze={handleAnalyze}
        title={defaultTitle}
        start={defaultStart}
        end={defaultEnd}
        sending={false}
      />
    </Layout>
  );
}
