import React, { Component } from 'react';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import { SnackbarProvider } from 'notistack';
import CircularProgress from '@material-ui/core/CircularProgress';
import paths from '../paths';
import NotFound from '../components/NotFound';
import Monitorings from '../../Monitorings/presentation/Container';
import Reports from '../../Reports/presentation/Container';
import Login from '../../Auth/presentation/Login';
import AuthService from '../../Auth/services/AuthService';

class App extends Component {
  state = {
    user: null,
    authChecked: false
  };

  componentDidMount() {
    AuthService.onAuthStateChanged(user => {
      this.setState({
        user,
        authChecked: true
      });
    });
  }

  render() {
    const { user, authChecked } = this.state;

    if (!authChecked) {
      return (
        <div>
          <CircularProgress size={24} />
        </div>
      );
    }

    if (!user) {
      return (
        <Router>
          <Switch>
            <Route exact path={paths.HOME} component={Login} />
            <Route component={NotFound} />
          </Switch>
        </Router>
      );
    }

    return (
      <SnackbarProvider maxSnack={3}>
        <Router>
          <Switch>
            <Route exact path={paths.HOME} component={Monitorings} />
            <Route exact path={paths.MONITORINGS} component={Monitorings} />
            <Route exact path={paths.REPORTS} component={Reports} />
            <Route component={NotFound} />
          </Switch>
        </Router>
      </SnackbarProvider>
    );
  }
}

export default App;
