import Dispatcher from '../../Core/Dispatcher';
import Monitoring from '../domain/Monitoring';

class MonitoringsRepository {
  async retrieve(token: string): Promise<Monitoring[]> {
    const monitoringsResponse = await Dispatcher.dispatchQuery({
      name: 'monitorings',
      token
    });
    return monitoringsResponse.data.monitorings;
  }
}

export default new MonitoringsRepository();
