import firebase from 'firebase/app';
import 'firebase/auth';
import 'firebase/database';
import AccessToken from '../domain/AccessToken';
import User, { Email, Password } from '../domain/User';

export type AuthStateChangedCb = (user: User | null) => {};

class AuthService {
  private authStateChanged: AuthStateChangedCb | null = null;
  private auth: firebase.auth.Auth;

  constructor() {
    const config = JSON.parse(process.env.REACT_APP_FIREBASE_CONFIG || '');
    firebase.initializeApp(config);
    this.auth = firebase.auth();
    this.auth.onAuthStateChanged((fireBaseUser: firebase.User | null) => {
      const user = fireBaseUser ? this.mapUser(fireBaseUser) : null;
      this.changeAuthState(user);
    });
  }

  async login(email: Email, password: Password, remember: true): Promise<User> {
    try {
      const storage = remember ? firebase.auth.Auth.Persistence.LOCAL : firebase.auth.Auth.Persistence.SESSION;
      await this.auth.setPersistence(storage);

      const loginReponse = await this.auth.signInWithEmailAndPassword(email, password);
      if (!loginReponse.user) {
        throw new Error('empty user');
      }
      return this.mapUser(loginReponse.user);
    } catch (error) {
      this.changeAuthState(null);
      throw new Error('Invalid email and password combination');
    }
  }

  async logout(): Promise<void> {
    await this.auth.signOut();
  }

  onAuthStateChanged = (cb: AuthStateChangedCb): void => {
    this.authStateChanged = cb;
  };

  currentUser(): User | null {
    const user = this.auth.currentUser;
    return user ? this.mapUser(user) : null;
  }

  async token(): Promise<AccessToken | null> {
    const user = this.auth.currentUser;
    if (!user) {
      return null;
    }
    return await user.getIdToken();
  }

  private changeAuthState(user: User | null): void {
    const authStateChanged = this.authStateChanged ? this.authStateChanged.bind(this) : null;
    authStateChanged && authStateChanged(user);
  }

  private mapUser(user: firebase.User): User {
    return {
      id: user.uid,
      email: user.email || ''
    };
  }
}

export default new AuthService();
