import React, { PureComponent } from 'react';
import LoginForm from './LoginForm';
import AuthService from '../services/AuthService';

class Login extends PureComponent {
  state = {
    fetching: false,
    error: null
  };

  handleLoginUser = async (inputEmail, inputPassword, remember) => {
    this.setState({ fetching: true });
    const email = inputEmail.trim();
    try {
      const user = await AuthService.login(email, inputPassword, remember);
      console.log('LOGGING IN:', { email, inputPassword, remember }, user);
    } catch (error) {
      this.setState({ error: error.message, fetching: false });
      console.log('Error IN:', { email, inputPassword, remember }, this.state);
    }
  };

  render() {
    const { error, fetching } = this.state;
    return <LoginForm error={error} fetching={fetching} onSubmit={this.handleLoginUser} />;
  }
}

export default Login;
