import Dispatcher from '../../Core/Dispatcher';
import GroupId from '../../Monitorings/domain/GroupId';
import MonitoringId from '../../Monitorings/domain/MonitoringId';
import Report from '../domain/Report';
import ReportId from '../domain/ReportId';

class ReportsRepository {
  async retrieve(token: string): Promise<Report[]> {
    const reportsResponse = await Dispatcher.dispatchQuery({
      name: 'reports',
      token
    });
    return reportsResponse.data.reports;
  }

  async create(
    token: string,
    monitoringId: MonitoringId,
    groupId: GroupId,
    title: string,
    start: string,
    end: string
  ): Promise<void> {
    const reportId = ReportId.create().value;
    await Dispatcher.dispatchCommand({
      name: 'reports',
      token,
      data: { reportId, monitoringId, groupId, title, start, end }
    });
    return;
  }
}

export default new ReportsRepository();
