import React from 'react';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import Title from '../../App/components/Title';
import { Button } from '@material-ui/core';
import LoadingTableRow from '../../App/components/LoadingTableRow';

export default function MonitoringsList(props) {
  const { monitorings, handleOpenAnalyze } = props;

  function createHandleAnalyze(id, name, groupId, start, end) {
    return e => {
      e.preventDefault();
      handleOpenAnalyze(id, name, groupId, start, end);
    };
  }

  return (
    <React.Fragment>
      <Title>Monitorings</Title>
      <Table size="small">
        <TableHead>
          <TableRow>
            <TableCell>Name</TableCell>
            <TableCell>Description</TableCell>
            <TableCell>Type</TableCell>
            <TableCell>Enabled</TableCell>
            <TableCell align="right">Actions</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {!monitorings && <LoadingTableRow colSpan={5} />}
          {monitorings && !monitorings.length && (
            <TableRow>
              <TableCell colSpan={5} align={'center'}>
                There are no monitorings.
              </TableCell>
            </TableRow>
          )}
          {monitorings &&
            monitorings.map(monitoring => (
              <TableRow key={monitoring.id}>
                <TableCell>{monitoring.name}</TableCell>
                <TableCell>{monitoring.description}</TableCell>
                <TableCell>{monitoring.type}</TableCell>
                <TableCell>{monitoring.enabled ? <CheckBoxIcon /> : <CheckBoxOutlineBlankIcon />}</TableCell>
                <TableCell align="right">
                  <Button
                    onClick={createHandleAnalyze(
                      monitoring.id,
                      monitoring.name,
                      monitoring.groupId,
                      monitoring.resultsStart,
                      monitoring.resultsEnd
                    )}
                  >
                    Analyze
                  </Button>
                </TableCell>
              </TableRow>
            ))}
        </TableBody>
      </Table>
    </React.Fragment>
  );
}
