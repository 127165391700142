import React from 'react';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Title from '../../App/components/Title';
import LoadingTableRow from '../../App/components/LoadingTableRow';
import { Button } from '@material-ui/core';

export default function ReportsList(props) {
  const { reports } = props;

  return (
    <React.Fragment>
      <Title>Reports</Title>
      <Table size="small">
        <TableHead>
          <TableRow>
            <TableCell>Title</TableCell>
            <TableCell>Status</TableCell>
            <TableCell align="right">Actions</TableCell>
          </TableRow>
        </TableHead>
        {!reports && <LoadingTableRow colSpan={3} />}
        {reports && !reports.length && <TableRow><TableCell colSpan={3} align={'center'}>There are no reports.</TableCell></TableRow>}
        <TableBody>
          {reports && reports.map(report => (
            <TableRow key={report.id}>
              <TableCell>{report.title}</TableCell>
              <TableCell>{report.status}</TableCell>
              <TableCell align="right">{report.link && <Button href={report.link} target={'blank'}>View</Button>}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </React.Fragment>
  );
}