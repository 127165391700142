import React from 'react';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import CircularProgress from '@material-ui/core/CircularProgress';

export default function LoadingTableRow({ colSpan }) {
  return (
    <TableRow>
      <TableCell colSpan={colSpan} align="center"><CircularProgress size={24}/></TableCell>
    </TableRow>
  );
}